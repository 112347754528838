@import "../../styles/mixins";

.container {
  padding: 20px 56px 0;

  @include sm {
    padding: 0 16px;
  }
  .title {
    font-size: 44px;
    font-weight: 500;
    line-height: 106%;
    letter-spacing: -1px;

    @include xs {
      font-size: 32px;
    }
  }
}

.contentEnterBox {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  height: 320px;

  @include sm {
    flex-direction: column;
  }
}

.themeSwitchContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  margin: 0 0 20px;

  span {
    font-size: 11px;
    font-weight: 400;
    line-height: 130%;
  }
}
