.footer {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  height: 58px;
  z-index: 13;
  width: 100%;
  border-radius: 0px 0px 10px 10px;

  div {
    display: flex;
    justify-content: flex-end;
    padding: 0 40px;
    width: 100%;
  }
}
