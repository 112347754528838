.button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 110%; /* 15.4px */
  letter-spacing: -0.5px;
  background: #000;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  max-height: 34px;

  &:disabled {
    background: rgba(0, 0, 0, 0.65);
  }
}

.buttonDark {
  color: #0d0d0d;
  font-size: 14px;
  font-weight: 500;
  line-height: 110%; /* 15.4px */
  letter-spacing: -0.5px;
  background: #fff;

  &:disabled {
    background: rgba(255, 255, 255, 0.65);
  }
}
