.typingAnimation {
  position: relative;
  display: flex;
  border-radius: 6px;
  padding: 4px 8px;
  background: #f2f2f7;
  gap: 8px;
  max-width: 30%;
}

.typingAnimation p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.typingAnimation span {
  display: inline-block;
  padding-right: 10px; /* adjust this value to control the animation speed */
}
