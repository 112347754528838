@import "../../styles/mixins";

.fileUploadContainer {
  width: 100%;
}

.container {
  display: flex;
  padding: 61px 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 16px;

  @include md {
    padding-bottom: 43px;
  }

  @include sm {
    padding: 56px 0;
  }
}

.uploadIconBox {
  margin-top: 40px 0 24px;
}

.dndText {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 12px;

  @include xs {
    padding: 0 10px;
  }

  span {
    color: #5662f6;
    font-weight: 600;
  }
}

.fileSizeText {
  opacity: 0.4;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 16px;
}

.uploadInput {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.5px;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  max-height: 34px;
}

.fileBox {
  .fileAdded {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  .file {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .infoBox {
      display: flex;
      align-items: center;
      gap: 17px;

      .iconBox {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .fileName {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 3px;
      }

      .fileSize {
        font-size: 10px;
        font-weight: 400;
      }
    }

    .closeIconBox {
      cursor: pointer;
    }
  }
}
