@import "../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .messagesContainer {
    display: flex;
    flex-direction: column;

    .messageBox {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      margin: 0 16px 16px 0;
    }
  }
}
.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border-top: 1px solid #e5e5ea;

  .input {
    width: 100%;
    outline: 0;
    border: none;
    padding: 14px 0px;
  }

  .button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.btnModulesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 20px;

  @include sm {
    margin: 0 auto 20px;
  }
}

.inputBox {
  background: #fff;
}

.loading {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

.typingAnimation {
  position: relative;
  border-radius: 6px;
  padding: 4px 8px;
  background: #f2f2f7;
  gap: 8px;
}

.skeletonContainer {
  display: flex;
  max-width: 30%;
  gap: 8px;
  margin: 0 0 30px 30px;

  div {
    width: 100%;
  }
}
