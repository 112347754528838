.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 40px;
  gap: 10px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .title {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.5px;
    text-decoration: none;
    background: none;
    border: none;

    span {
      color: #5662f6;
    }
  }
}

.modalBody {
  max-width: 385px;
  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .description {
    color: rgba(84, 89, 94, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .btnBox {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .noBtn {
      display: flex;
      height: 44px;
      padding: 14px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 1px solid #000;
      background: #fff;
      width: 100%;
      cursor: pointer;
    }

    .yesBtn {
      display: flex;
      height: 44px;
      padding: 14px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 1px solid #000;
      background: #000;
      color: #fff;
      width: 100%;
      cursor: pointer;
    }
  }
}

///////////////
.containerDark {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 40px;
  gap: 10px;
  box-shadow: 0px 1px 10px 0px rgba(255, 255, 255, 0.12);
  background: #202020;

  .title {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.5px;
    text-decoration: none;
    background: none;
    border: none;

    span {
      color: #5662f6;
    }
  }
}

.modalBodyDark {
  max-width: 385px;

  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    background: none;
  }

  .description {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .btnBox {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .noBtn {
      display: flex;
      height: 44px;
      padding: 14px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 1px solid #fff;
      color: #fff;
      background: #0d0d0d;
      width: 100%;
      cursor: pointer;
    }

    .yesBtn {
      display: flex;
      height: 44px;
      padding: 14px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 1px solid #fff;
      background: #fff;
      color: #161616;
      width: 100%;
      cursor: pointer;
    }
  }
}
