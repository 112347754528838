@mixin xs {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin retina {
  @media (min-resolution: 2dppx) {
    @content;
  }
}
