@import "../../styles/mixins";
.container {
  display: flex;
  margin: 0 0 30px 30px;
  gap: 8px;

  .senderBox {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    line-height: 18px; /* 138.462% */
    letter-spacing: -1px;
    min-width: 32px;
    height: 32px;
    background: #5662f6;
    border-radius: 100px;

    @include sm {
      margin-left: 16px;
    }
  }

  .message {
    display: flex;
    border-radius: 0px 6px 6px 0px;
    padding: 4px 8px;
    background: #f2f2f7;
    gap: 8px;
    max-width: 40%;
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .senderName {
        color: #2c2c2e;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
      }

      .messageText {
        color: #2c2c2e;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }

    .timeBox {
      display: flex;
      align-items: flex-end;

      .time {
        white-space: nowrap;
        color: #666668;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .messageDark {
    display: flex;
    border-radius: 0px 6px 6px 0px;
    padding: 4px 8px;
    background: #272727;
    gap: 8px;
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .senderName {
        color: #d1d1d3;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
      }

      .messageText {
        color: #d1d1d3;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }

    .timeBox {
      display: flex;
      align-items: flex-end;

      .time {
        white-space: nowrap;
        color: #666668;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .userMessage {
    display: flex;
    border-radius: 0px 6px 6px 0px;
    padding: 4px 8px;
    background: #5662f6;
    gap: 8px;
    max-width: 40%;
    align-self: flex-end;
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .senderName {
        color: #2c2c2e;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
      }

      .messageText {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }

    .timeBox {
      display: flex;
      align-items: flex-end;

      .time {
        color: #fff;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .userMessageDark {
    display: flex;
    border-radius: 0px 6px 6px 0px;
    padding: 4px 8px;
    background: #545ba8;
    gap: 8px;
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .messageText {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }

    .timeBox {
      display: flex;
      align-items: flex-end;

      .time {
        color: #fff;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
