@import "../../styles/mixins";

.container {
  display: flex;
  height: 95vh;

  @include sm {
    justify-content: space-between;
    flex-direction: column;
    height: 95vh;
  }
}
.chatContainer {
  padding: 20px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;

  @include sm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    width: 92%;
    margin: 0 auto;
    height: 100%;
  }

  .chatWord {
    color: #000;
    font-size: 22px;
    font-weight: 600;
    line-height: 100%;

    @include sm {
      display: none;
    }
  }
}
