@import "../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  min-width: 300px;
  max-height: 95vh;
  overflow-y: auto;
  backdrop-filter: blur(2px);

  @include sm {
    max-width: 100%;
    max-height: 100%;
  }

  .innerBox {
    padding: 24px 16px 0 40px;

    @include sm {
      padding: 24px 24px 0;
    }

    @include xs {
      padding: 24px 16px 0;
      height: 90vh;
    }

    .infoBox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .closeIconBox {
        cursor: pointer;
      }

      .description {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.iconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  background: #5662f6;
  box-shadow: 2px 1px 10px 0px rgba(0, 0, 0, 0.35);
  min-width: 40px;
  width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;

  @include xs {
    max-width: 40px;
  }
}

.clearIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  background: #5662f6;
  box-shadow: 2px 1px 10px 0px rgba(0, 0, 0, 0.35);
  min-width: 40px;
  width: 40px;
  height: 40px;
  margin: 44px 44px 44px 60px;
  cursor: pointer;

  @include xs {
    margin: 44px 25px 40px 35px;
    max-width: 40px;
  }
}

.textBox {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .fileName {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.46px;
  }

  .originalText {
    color: #5a5a5a;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
