.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  border-radius: 360px;
  background: rgba(86, 98, 246, 0.1);
  box-shadow: 0px 0px 1px 0px rgba(86, 98, 246, 0.8);
}
.toggleSwitch input[type="checkbox"] {
  display: none;
}
.toggleSwitch .switch {
  position: absolute;
  cursor: pointer;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggleSwitch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 1px;
  width: 18px;
  height: 18px;
  background: #5662f6;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggleSwitch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(14px);
  background: #5662f6;
}
.toggleSwitch input[type="checkbox"]:checked + .switch {
  box-shadow: 0px 0px 1px 0px rgba(86, 98, 246, 0.8);
  background: rgba(110, 119, 247, 0.2);
}
