@import "../../styles/mixins";

.textAreaContainer {
  width: 100%;
  border-radius: 10px;
  padding: 16px;

  @include sm {
    width: auto;
    min-height: 100%;
  }

  textarea {
    resize: none;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
  }
}
