@import "../styles/mixins";

body {
  font-family: "IBM Plex Sans", sans-serif;
  min-height: 100vh;

  @include sm {
    overflow-x: hidden;
  }
}

#root {
  height: 100%;
  min-height: 100vh;
}

.dark {
  color: #fff;
  background: #202020;
}

.light {
  color: #000;
  background: #fff;
}

.rnc__notification-item {
  font-family: "IBM Plex Sans" !important;
}

.rnc__notification-container--top-right {
  .rnc__notification {
    width: fit-content !important;
    .rnc__notification-item--success {
      border-radius: 8px;
      box-shadow:
        0px 20px 20px 0px rgba(0, 0, 0, 0.08),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);

      .rnc__notification-content {
        display: flex;
        padding: 18px 20px;
        flex-direction: column;
        gap: 8px;
        .rnc__notification-title {
          font-family: "IBM Plex Sans";
          font-size: 18px;
          font-weight: 500;
        }

        .rnc__notification-message {
          font-family: "IBM Plex Sans";
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          white-space: nowrap;
        }
      }
    }
  }
}

.notificationLight {
  .rnc__notification-container--top-right {
    .rnc__notification {
      width: fit-content !important;
      .rnc__notification-item--success {
        background-color: #5662f6;
        box-shadow:
          0px 20px 20px 0px rgba(0, 0, 0, 0.08),
          0px 0px 2px 0px rgba(0, 0, 0, 0.12);
        border-left: 0px solid #5662f6;

        .rnc__notification-timer-filler {
          background-color: #5662f6;
        }
      }
    }
  }
}

.notificationDark {
  .rnc__notification-container--top-right {
    .rnc__notification {
      width: fit-content !important;
      .rnc__notification-item--success {
        background-color: #6e77f7;
        box-shadow:
          0px 20px 20px 0px rgba(0, 0, 0, 0.08),
          0px 0px 2px 0px rgba(0, 0, 0, 0.12);
        border-left: 0px solid #6e77f7;

        .rnc__notification-timer-filler {
          background-color: #6e77f7;
        }
      }
    }
  }
}
